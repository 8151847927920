import React from "react";
import styled from "styled-components";

import IconLeftArrow from "../../../assets/icons/left-arrow.svg";

const PageControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0;

  .content {
    padding: .5rem 1rem;
    min-width: 17rem;
    background: #f0f9ff;
    text-align: center;
    
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.8rem;

    svg {
      cursor: pointer;
    }

    &--left {
      margin-right: 1rem;
    }
    &--right {
      margin-left: 1rem;
    }
  }
`;

const PartsNaigationPageControl = ({ activePage, setActivePage, pageCount }) => {
  return (
    <PageControlContainer>
      <div className="button button--left">
        {activePage > 1 && (
          <IconLeftArrow
            width="18"
            height="18"
            onClick={() => {
              setActivePage(activePage - 1)
            }}
          />
        )}
      </div>
      <div className="content">
        Страница { activePage } из { pageCount }
      </div>
      <div className="button button--right">
        {activePage < pageCount && (
          <IconLeftArrow
            width="18"
            height="18"
            style={{
              transform: 'rotate(180deg)'
            }}
            onClick={() => {
              setActivePage(activePage + 1)
            }}
          />
        )}
      </div>
    </PageControlContainer>
  )
}

export default PartsNaigationPageControl