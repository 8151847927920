export default class PartsFilter {

  static filter(filters, search, items) {
    let filtered = [];
    const categoryFilters = [];
    const modelFilters = [];

    filters.forEach(item => {
      switch (item.type) {
        case 'category':
          categoryFilters.push(item.value);
          break;
        case 'model':
          modelFilters.push(item.value);
          break;
        default:
          break;
      }
    })

    // 1. Фильтруем по категориям
    categoryFilters.length ? filtered = items.filter(item => categoryFilters.includes(item.category)) : filtered = items;
    // 2. Фильтруем по моделям
    if (modelFilters.length) {
      filtered = filtered.filter(item => modelFilters.includes(item.model))
    }
    // 3. Фильтруем по строке поиска
    if (search.length) {
      filtered = filtered.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
    }
    return filtered;
  }
}