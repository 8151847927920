import React from "react";
import { useMediaQuery } from 'react-responsive';
import { connect } from "react-redux";

import { StyledTable, NoItemsMessage } from "./styles";

import PartsDataTableItem from "./PartsDataTableItem";
import PartsNaigationPageControl from "./PartsNaigationPageControl";

import PartsFilter from "../../../features/Parts/PartsFilter";
import PartsPagination from "../../../features/Parts/PartsPagination";
import PartsDataTableItemMobile from "./PartsDataTAbleItemMobile";

const PartsDataTable = ({ partsItems, filters, search }) => {
  const [activePage, setActivePage] = React.useState();
  const [pageCount, setPageCount] = React.useState(0);
  const [pageItems, setPageItems] = React.useState([]);
  const [displayedItems, setDisplayedItems] = React.useState([]);

  // TODO: Move to a custom hook
  React.useEffect(() => {
    // 1. Фильтруем перечень деталей
    const filteredItems = PartsFilter.filter(filters, search, partsItems);
    // 2. Разбиваем на страницы
    const paginationItems = PartsPagination.formatItems(filteredItems);
    // 3. Записываем общее количество страниц
    setPageCount(paginationItems.length);
    // 4. Записываем массив страниц
    setPageItems(paginationItems);
    // 5. Ставим активной первую страницу
    setActivePage(1);
    const displayedItems = paginationItems[0].items || [];
    setDisplayedItems(displayedItems)
  },[filters, search])

  React.useEffect(() => {
    if (activePage > 1) {
      const displayedItems = pageItems[activePage - 1].items || [];
      setDisplayedItems(displayedItems)
    }
  }, [activePage]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 940px)' });

  return (
    <>
      { pageCount > 1 && (
        <PartsNaigationPageControl
          pageCount={ pageCount }
          activePage={ activePage }
          setActivePage={ setActivePage }
        />
      ) }
      <StyledTable>
        {/* Отображаем заголовки только для десктопа */}
        { !isTabletOrMobile && (
          <thead>
            <tr>
              <th>Наименование</th>
              <th>Модель</th>
              <th>Категория</th>
              <th>Цена</th>
              <th></th>
            </tr>
          </thead>
        )}
        <tbody>
          { displayedItems.length ? 
            isTabletOrMobile ? 
            displayedItems.map(item => (
              <PartsDataTableItemMobile
                key={item.code}
                name={item.name}
                model={item.model}
                category={item.category}
                price={item.price}
                slug={item.slug}
              />
            ))
            :
            displayedItems.map(item => (
            <PartsDataTableItem
              key={item.code}
              code={item.code}
              name={item.name}
              model={item.model}
              category={item.category}
              price={item.price}
              slug={item.slug}
            />
          )) : (
            <tr className="no-hover">
              <td colSpan="5">
                <NoItemsMessage>
                  <span>К сожалению, мы не смогли найти продукцию</span>
                </NoItemsMessage>
              </td>
          </tr>
          )}
        </tbody>
      </StyledTable>
    </>
  );
}

export default connect(state => ({
  filters: state.app.filters,
  search: state.app.search,
}), null)(PartsDataTable)
