import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import styled from "styled-components";

import { 
  StyledFilter,
  FilterHeading,
  FilterBody,
} from "../styles/PartsNavigationFilter";
import PartsNavigationFilterSelect from "./PartsNavigationFilterSelect";
import IconChimp from "../../../../assets/icons/backward-arrow.svg";

import { connect } from "react-redux";
import ModelFiltersFactory from "../../../../features/Parts/ModelFiltersFactory";

const PartsNavigationModelFilter = ({
  modelFilterOpen,
  setModelFilterOpen,
  updateFilters,
  filters,
  isMobile
}) => {
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  // Загружаем список моделей
  const data = useStaticQuery(graphql`
    query ALL_MODELS_FILTERS_QUERY {
      config:configYaml(type: {eq: "models"}) {
         models{
          title
          slug
        }
      }
    }
  `);
  const modelFilters = ModelFiltersFactory.fromArray(data.config.models);

  React.useEffect(() => {
    const categoryFilters = filters.filter(el => el.type === 'model');
    setSelectedFilters(categoryFilters);
  },[filters])

  const handleSelect = filters => {
    updateFilters(filters);
  }

  const handleMouseEvent = (e) => {
    e.preventDefault();
    if (!isMobile) {
      setModelFilterOpen(!modelFilterOpen);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (isMobile) {
      setModelFilterOpen(!modelFilterOpen);
    }
  }

  return (
    <StyledFilter
      onMouseEnter={(e) => handleMouseEvent(e)}
      onMouseLeave={(e) => handleMouseEvent(e)}
    >
      <FilterHeading
        onClick={(e) => handleClick(e)}
      >
          <span>Модель</span>
          <IconChimp
            width="15"
            height="15"
            fill="currentColor"
            style={{
              transform: modelFilterOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
            }}
          />
      </FilterHeading>
      <FilterBody className={modelFilterOpen && 'active'}>
        <PartsNavigationFilterSelect
          items={modelFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={handleSelect}
        />
      </FilterBody>
    </StyledFilter>
  )
}

export default connect(state => ({
  filters: state.app.filters,
}), null)(PartsNavigationModelFilter)