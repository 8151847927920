import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

import PartsDataTable from "../components/Parts/PartsDataTable";
import PartsNavigation from "../components/Parts/PartsNavigation";

import PartsItemsFactory from "../features/Parts/PartsItemFactory";
import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";

const breadcrumbsFactory = new BreadcrumbsFactory();

const StyledContainer = styled.div`
  padding: 1rem 0 6rem 0;
  .subheading {
    margin-bottom: 1.5rem;
  }
`;

const infoText = "Обращаем ваше внимание, что ассортимент деталей очень обширный, всегда в наличие имеется огромный выбор деталей и если вы не смогли найти нужную деталь, просьба позвонить нам по телефону или оставить заявку через форму обратной связи.";

const Parts = ({ data }) => {
  // Объект данных переданных в компонент при переходе по ссылке
  const partsItemsFactory = new PartsItemsFactory(data.config.models);
  const allPartsItems = partsItemsFactory.fromArray(data.items.nodes);
  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'parts',
      title: 'Каталог запчастей',
    },
  ]);

  return (
    <Layout crumbs={ crumbs }>
      <SEO title="Детали для Крузаков" />
      <StyledContainer className="container">
        <h1>Каталог запчастей</h1>
        <div className="subheading">{infoText}</div>
        <PartsNavigation />
        <PartsDataTable partsItems={ allPartsItems } />
      </StyledContainer>
    </Layout>
  )
};

export const query = graphql`
  query ALL_PARTS {
    items:allPricelistXlsxCatalog {
      nodes {
        name
        category
        code
        model
        note
        price
      }
    }
    config:configYaml(type: {eq: "models"}) {
      models {
        title
        slug
      }
    }
  }
`;

export default Parts
