export default class PartsPagination {

  static formatItems(allItems, perPage = 30) {
    if (!allItems.length) return [{page: 1, items: []}];

    const formattedItems = [];
    const numberOfPages = PartsPagination.getNumberOfPages(allItems.length, perPage);
    for (let i = 1; i <= numberOfPages; i++) {
      const pageData = {
        page: i,
        items: [],
      };
      if (i === numberOfPages) {
        pageData.items = allItems.slice((i - 1) * perPage);
      } else {
        pageData.items = allItems.slice(((i - 1) * perPage), i * perPage );
      }
      formattedItems.push(pageData);
    }
    return formattedItems;
  }

  static getNumberOfPages(total, perPage) {
    return Math.ceil(total / perPage);
  }

}