import React from "react";

import PartsNavigationAppliedFilters from "./PartsNavigationAppliedFilters";
import PartsNavifationFilters from "./PartsNavigationFilters";

const PartsNavigation = () => (
  <div>
    <PartsNavigationAppliedFilters />
    <PartsNavifationFilters />
  </div>
)

export default PartsNavigation