import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { 
  StyledFilter,
  FilterHeading,
  FilterBody,
} from "../styles/PartsNavigationFilter";

import PartsNavigationFilterSelect from "./PartsNavigationFilterSelect";
import IconChimp from "../../../../assets/icons/backward-arrow.svg";

import { connect } from "react-redux";
import CategoryFiltersFactory from "../../../../features/Parts/CategoryFiltersFactory";

const PartsNavigationCategoryFilter = ({
  categoryFilterOpen,
  setCategoryFilterOpen,
  switchFilters,
  updateFilters,
  filters,
  isMobile
}) => {
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  // Загружаем список категорий
  const data = useStaticQuery(graphql`
    query ALL_CATEGORIES_FILTERS_QUERY {
      categories: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/categories/"}}, sort: {fields: frontmatter___sort}) {
        nodes {
          frontmatter {
            title: shortName
            slug
          }
        }
      }
    }
  `);
  const categoryFilters = CategoryFiltersFactory.fromArray(data.categories.nodes);
  
  React.useEffect(() => {
    const categoryFilters = filters.filter(el => el.type === 'category');
    setSelectedFilters(categoryFilters);
  },[filters])

  const handleSelect = filters => {
    updateFilters(filters);
  }

  const handleMouseEvent = (e) => {
    e.preventDefault();
    if (!isMobile) {
      setCategoryFilterOpen(!categoryFilterOpen);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (isMobile) {
      setCategoryFilterOpen(!categoryFilterOpen);
    }
  }

  return (
    <StyledFilter
      onMouseEnter={(e) => handleMouseEvent(e)}
      onMouseLeave={(e) => handleMouseEvent(e)}
    >
      <FilterHeading
        onClick={(e) => handleClick(e)}
      >
          <span>Категория</span>
          <IconChimp
            width="15"
            height="15"
            fill="currentColor"
            style={{
              transform: categoryFilterOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
            }}
          />
      </FilterHeading>
      <FilterBody className={categoryFilterOpen && 'active'}>
        <PartsNavigationFilterSelect
          items={categoryFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={handleSelect}
        />
      </FilterBody>
    </StyledFilter>
  )
}

export default connect(state => ({
  filters: state.app.filters,
}), null)(PartsNavigationCategoryFilter)