import {Model, QueryPartsItem} from "./Types";

export default class SlugFactory {
  private models: Model[];

  constructor(models: Model[]) {
    this.models = models
  }

  create (partsItem: QueryPartsItem): string {
    const modelSlug = this.models.find(model => model.title === partsItem.model)?.slug
    if (!modelSlug) {
      return partsItem.code
    }

    return `${modelSlug}-${partsItem.code}`
  }
}