import React from "react";

import { FilterSelect } from "../../styles/PartsNavigationFilter";
import PartsNavigationFilterSelectItem from "./PartsNavigationFilterSelectItem";

const PartsNavigationFilterSelect = ({ items, selectedFilters, setSelectedFilters }) => {

  const handleChange = item => {
    if (selectedFilters.map(filter => filter.value).includes(item.value)) {
      setSelectedFilters([...selectedFilters.filter(el => el.value !== item.value)]);
    } else {
      setSelectedFilters([...selectedFilters, item])
    }
  }

  return (
    <FilterSelect>
      {items.map(item => (
        <PartsNavigationFilterSelectItem
          key={item.value}
          item={item}
          isSelected={selectedFilters.map(filter => filter.value).includes(item.value)}
          selectedFilters={selectedFilters}
          handleChange={handleChange}
        />
      ))}
    </FilterSelect>
  )
}

export default PartsNavigationFilterSelect