import styled from "styled-components";

export const StyledTable = styled.table`
  border-radius: .8rem;
  border-collapse: collapse;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  width: 100%;
  overflow: hidden;

  thead {
    text-align: left;
    border-bottom: 1px solid #abb4bb;
    color: #101417;
    th {
      font-weight: 400;
      font-size: 1.8rem;
      letter-spacing: 2px;
      padding: 1.5rem .8rem;
    }
  }
  tbody {
    tr:not(.no-hover) {
      :hover {
        background-color: #ebf2fd;
      }
    }
  }
`;

export const NoItemsMessage = styled.div`
  min-height: 25vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;