import styled from 'styled-components';

export const StyledFilter = styled.div`
  /* background: #eee; */

  position: relative;
`;

export const FilterHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;

  border: 2px solid #414c52;
  
  cursor: pointer;
`;

export const FilterBody = styled.div`
  background: #f0f9ff;
  position: absolute;
  width: auto;
  max-width: 28rem;
  z-index: 99;

  color: #233540;

  display: none;

  &.active {
    display: block;
  }

  @media (max-width: 940px) {
    max-width: 100%;
    width: 100%;
  }
  
`;

export const FilterSelect = styled.ul`
  width: 28rem;
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1.5rem 0;

  li {
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: 1.4rem 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    cursor: pointer;

    .checkbox {
      border: 2px solid #233540;
      border-radius: .1rem;
      width: 1.2rem;
      height: 1.2rem;
      content: "";
      position: relative;

      &.active {
        background: #FDB819;
      }
    
    }

    :hover .title {
      color: #c16200;
      text-decoration: underline;
    }
  }
  @media (max-width: 940px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const FilerAction = styled.div`
  border: 1px solid #333;
  padding: 1rem 1.5rem;
  text-align: center;
  cursor: pointer;
`;