import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import formatPrice from "../../../features/Helpers/formatPrice";

import { connect } from "react-redux";
import { toggleModal, setModalData } from "../../../state/app";

const StyledRow = styled.tr`

  td {
    padding: 1.4rem 1rem;
    border: none;
    line-height: normal;

    &.name { 
      width: 50%;
    }
    &.category { 
      width: 15%;
    }
    &.model { 
      width: 15%;
    }
    &.price { width: 10%; }
    
    &.action {
      width: 10%;
      text-align: center;

      span {
        display: block;
        transition: transform .2s ease-in-out;
        transform: translateX(200%);
        padding: .4rem;
        /* background: #ffc31a; */
        background: #3ba4de;
        color: #fafafa;
        /* color: #fafafa; */
        border-radius: .2rem;
        cursor: pointer;
      }
    }
  }
  :hover td.action > span {
    transform: translateX(0);
  }
`

const PartsDataTableItem = ({ name, code, model, category, price, slug, dispatch }) => {
  return (
    <StyledRow>
      <td className="name">
        <Link to={`/parts/${slug}`} title={`${name} ${model}`}>{ name }</Link>
      </td>
      <td className="model">{ model }</td>
      <td className="category">{ category }</td>
      <td className="price">{ Boolean(price) ? formatPrice(price) : 'по запросу' }</td>
      <td className="action">
        <span
          onClick={() => {
            dispatch(setModalData({
              itemName: name,
              itemPrice: formatPrice(price),
              model,
              code,
              url: '/parts',
            }));
            dispatch(toggleModal());
          }}
        >Заявка</span>
      </td>
    </StyledRow>
  )
}

export default connect()(PartsDataTableItem)