import React from "react";
import styled from "styled-components";

import IconSearch from "../../../../assets/icons/search.svg";
import IconClose from "../../../../assets/icons/close-button.svg";

import { connect } from "react-redux";
import { setSearch, clearSearch } from "../../../../state/app";

const SearchContainer = styled.div`
  border: 2px solid #414c52;
  padding: 0 1rem;
  color: #63717f;

  display: flex;
  align-items: center;

  &.active {
    outline: 2px solid #C2E9FF;
  }

  .searchIcon {
    margin-right: .8rem;
  }
  .closeIcon {
    width: 1.5rem;
    svg {
      cursor: pointer;
    }
  }
  @media (min-width: 940px) {
    justify-self: end;
  }
`;

const StyledSearch = styled.input`
  border: none;
  padding: .5rem 1rem;
`

const PartsNavigationSearch = ({ search, dispatch }) => {

  const handleChange = event => {
    event.preventDefault();
    dispatch(setSearch(event.target.value));
  }

  return (
    <SearchContainer className={ search.length && 'active' }>
      <IconSearch className="searchIcon" height="15" width="15" fill="currentColor" />
      <StyledSearch
        type="text"
        placeholder="Название"
        value={ search }
        onChange={ handleChange }
        />
      <div className="closeIcon">
        { Boolean(search.length) && (
          <IconClose
            height="15"
            width="15"
            fill="currentColor"
            onClick={() => dispatch(clearSearch())}
          />
        )}
      </div>
    </SearchContainer>
  )
}

export default connect(state => ({
  search: state.app.search,
}))(PartsNavigationSearch)