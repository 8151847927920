import React from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { updateFilters } from "../../../../state/app";

const StyledButton = styled.div`
  padding: .8rem 2.2rem;
  margin: 0 1.4rem 1rem 0;
  display: inline-flex;
  align-items: center;
  background: #ffc6a5;
  cursor: pointer;
`

const PartsAppliedFiltersClearButton = ({ dispatch }) => {
  return (
    <StyledButton
      onClick={() => dispatch(updateFilters([]))}
    >Очистить</StyledButton>
  )
}

export default connect()(PartsAppliedFiltersClearButton)