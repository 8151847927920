import React from 'react';
import {connect} from "react-redux"

import PartsAppliedFiltersChimp from "./PartsAppliedFiltersChimp";
import PartsAppliedFiltersClearButton from "./PartsAppliedFiltersClearButton";

import { removeFilter } from "../../../../state/app";

const PartsAppliedFilters = ({filters, dispatch}) => {

  const handleFilterRemove = value => {
    dispatch(removeFilter(value));
  }

  return (
    <div>
      { filters.map(filter => (
        <PartsAppliedFiltersChimp
          key={filter.value}
          value={filter.value}
          handleClick={handleFilterRemove}
        />
      ))}
      { Boolean(filters.length) && <PartsAppliedFiltersClearButton /> }
    </div>
  )
}

export default connect(state => ({
  filters: state.app.filters,
}), null)(PartsAppliedFilters)