import React from "react";
import styled from "styled-components";

const StyledItem = styled.li``;

const PartsNavigationFilterSelectItem = ({ item, isSelected, handleChange }) => {
  return (
    <StyledItem
      onClick={() => handleChange(item)}
    >
      <div className={ isSelected ? 'checkbox active' : 'checkbox' }/>
      <div className="title">{ item.value }</div>
    </StyledItem>
  )
}

export default PartsNavigationFilterSelectItem
