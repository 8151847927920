import Factory from '../../abstracts/Factory'
import {PartsItem} from '../../entities/PartsItem'
import SlugFactory from "./SlugFactory";
import {Model, QueryPartsItem} from "./Types";

export default class PartsItemFactory extends Factory<any, PartsItem> {
    private slugFactory: SlugFactory;

    constructor(models: Model[]) {
        super();
        this.slugFactory = new SlugFactory(models)
    }

    public create(input: QueryPartsItem): PartsItem {
        const slug = this.slugFactory.create(input)
        return {
            category: input.category,
            name: input.name,
            slug: slug,
            url: input.url,
            code: String(input.code),
            model: input.model,
            price: input.price,
            note: input.note,
        }
    }
}