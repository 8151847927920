import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import formatPrice from "../../../features/Helpers/formatPrice";

import { connect } from "react-redux";
import { toggleModal, setModalData } from "../../../state/app";

const StyledRow = styled.tr`
  border-bottom: 1px solid #eee;

  td {
    padding: 1.4rem 1rem;
    border: none;
    line-height: normal;

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.2rem;

      .name,
      .model,
      .action {
        grid-column: 1 / -1;
      }
      .price,
      .action {
        justify-self: end;
      }
      .name {
        font-weight: 600;
        margin-bottom: .5rem;
      }
      .model {
        color: #333;
      }
      .category {
        color: #8e8b8b;
      }
      .price {
        font-weight: 600;
      }
      .action {
        margin-top: 1rem;
        border: 1px solid #3ba4de;
        padding: .8rem 1.6rem;
      }
    }
  }
`

const PartsDataTableItemMobile = ({ name, code, model, category, price, slug, dispatch }) => {
  return (
    <StyledRow>
      <td colSpan="5">
        <div className="container">
          <div className="name">
            <Link to={`/parts/${slug}`}>{name}</Link>
          </div>
          <div className="model">{model}</div>
          <div className="category">{category}</div>
          <div className="price">{Boolean(price) ? formatPrice(price) : 'по запросу'}</div>
          <div className="action"
            onClick={() => {
              dispatch(setModalData({
                itemName: name,
                itemPrice: formatPrice(price),
                model,
                code,
                url: '/parts',
              }));
              dispatch(toggleModal());
            }}
          >Оставить заявку</div>
        </div>
      </td>
    </StyledRow>
  )
}

export default connect()(PartsDataTableItemMobile)