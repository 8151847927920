import CategoryFilter from "./CategoryFilterDTO";

export default class CategoryFiltersFactory {

  static fromArray(categoryApiArray) {
    const categoryFilters = [];
  
    categoryApiArray.forEach(categoryApiObject => {
        const categoryFilterDTO = CategoryFiltersFactory.createCategoryFilter(categoryApiObject);
        categoryFilters.push(categoryFilterDTO);
    });
    return categoryFilters;
  }

  static createCategoryFilter(categoryApiObject) {
    const categoryFilterDTO = new CategoryFilter(categoryApiObject);
    return categoryFilterDTO;
  }
}