import React from "react";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';

import PartsNavigationCategoryFilter from "./PartsNavigationCategoryFilter";
import PartsNavigationModelFilter from "./PartsNavigationModelFilter";
import PartsNavigationSearch from "./PartsNavigationSearch";

import { connect } from "react-redux";
import { updateCategoryFilters, updateModelFilters } from "../../../../state/app";

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(20rem, 28rem)) auto;
  grid-column-gap: 2rem;
  padding: 1rem 0;

  @media (max-width: 940px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-row-gap: 1rem;
  }
`;

const PartsNavigationFilters = ({ dispatch }) => {
  // Состояние компонентов с фильтрами
  const [ categoryFilterOpen, setCategoryFilterOpen ] = React.useState(false);
  const [ modelFilterOpen, setModelFilterOpen ] = React.useState(false);

  const updateCategoryFiltersInStore = filters => {
    dispatch(updateCategoryFilters(filters));
  }

  const updateModelFiltersInStore = filters => {
    dispatch(updateModelFilters(filters));
  }

  const isMobile = useMediaQuery({ query: 'screen and (max-width: 640px)' });

  return (
    <StyledContainer>
      {/* Category */}
      <PartsNavigationCategoryFilter
        isMobile={isMobile}
        categoryFilterOpen={categoryFilterOpen}
        setCategoryFilterOpen={setCategoryFilterOpen}
        updateFilters={updateCategoryFiltersInStore}
      />
      {/* Model */}
      <PartsNavigationModelFilter
        isMobile={isMobile}
        modelFilterOpen={modelFilterOpen}
        setModelFilterOpen={setModelFilterOpen}
        updateFilters={updateModelFiltersInStore}
      />
      <PartsNavigationSearch />
    </StyledContainer>
  )
}

export default connect()(PartsNavigationFilters)