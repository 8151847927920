import React from "react";
import styled from "styled-components";

import IconClose from "../../../../assets/icons/close-button.svg";

const StyledChimp = styled.span`
  padding: .8rem 2.2rem;
  margin: 0 1.4rem 1rem 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: #F4F2F0;

  span {
    margin-right: 2rem;
  }

  svg {
    cursor: pointer;
  }
`;

const PartsAppliedFiltersChimp = ({ value, handleClick }) => (
  <StyledChimp>
    <span>{ value }</span>
    <IconClose height="14" width="14" fill="currentColor" onClick={() => handleClick(value)} />
  </StyledChimp>
)

export default PartsAppliedFiltersChimp