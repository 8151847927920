import ModelFilter from "./ModelFilterDTO";

export default class ModelFiltersFactory {

  static fromArray(modelApiArray) {
    const modelFilters = [];
    modelApiArray.forEach(modelApiObject => {
      const modelFilterDTO = ModelFiltersFactory.createModelFilter(modelApiObject);
      modelFilters.push(modelFilterDTO);
    });
    return modelFilters;
  }

  static createModelFilter(modelApiObject) {
    const modelFilterDTO = new ModelFilter(modelApiObject);
    return modelFilterDTO;
  }
}